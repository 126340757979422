




























import {Vue, Component} from "vue-property-decorator";
import BusinessSucType from "./BusinessSuc";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import ShopAdminTitle from "@/views/ShopAdmin/components/Title/index.vue";
import BusinessSucList from "@/views/ShopAdmin/BusinessSuc/components/List/index.vue";
import { stateList,distanceList } from './Model/index';
import {getCommercial} from "@/views/ShopAdmin/BusinessSuc/Server";

@Component({name: "BusinessSuc",components:{ HeadTop,ShopAdminTitle,BusinessSucList }})
export default class BusinessSuc extends ZoomPage implements BusinessSucType{
    stateList:any[] = stateList
    distanceList:any[] = distanceList
    orderStateList = []
    orderAreaList = []
    List:any[] = []

    mounted(){
        this.loadingShow = false
        this.handleUpData()
    }

    handleUpData(){
        getCommercial().then(res=>{
            this.stateList = res.stateList
            this.distanceList = res.distanceList
            this.orderStateList = res.orderStateList
            this.orderAreaList = res.orderAreaList
            this.List = res.areaOrderList || []
        })
    }
}
