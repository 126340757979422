

















import {Vue, Component, Prop} from "vue-property-decorator";
import BusinessSucItemType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue"


@Component({name: "BusinessSucItem", components: {ScrollViewX}})
export default class BusinessSucItem extends Vue implements BusinessSucItemType {
    rightIcon = require("@/assets/icon/Basics/Right.png")
    // testList = ["01", "北苑二楼", "35", "36", "23", "86",]

    handleToPath(){
        if ( this.getState == "state" ){
            this.$router.push({
                name:"siteOrder",
                query:{
                    areaId:this.getData.areaId,
                    index:String( this.getIndex || '0' )
                }
            })
        }else{
            this.$router.push({
                name:"distance",
                query:{
                    areaId:this.getData.areaId,
                    index:String( this.getIndex || '0' )
                }
            })
        }
    }

    @Prop(String)
    color!: string
    get getColor() {
        return this.color || '#6d6d6d'
    }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(String)
    state!:string
    get getState(){ return this.state }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }
}
