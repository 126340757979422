















import {Vue, Component, Prop} from "vue-property-decorator";
import BusinessSucListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import BusinessSucItem from "@/views/ShopAdmin/BusinessSuc/components/Item/index.vue";

@Component({name: "BusinessSucList",components:{ PullDownUpList,ListBottom,BusinessSucItem }})
export default class BusinessSucList extends Vue implements BusinessSucListType{
    UpDownBool=false
    StopUp=false

    handleUpDown(){
        //
    }

    handleUpData(boll=false){
        //
    }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(Array)
    data!:any[]
    get getData(){ return this.data || [] }

    @Prop(String)
    state!:string
    get getState(){ return this.state }
}
